import React, { Component } from "react";
import ReactMde from "react-mde";
import ReactMarkdown from 'react-markdown'
import "react-mde/lib/styles/css/react-mde-all.css";

class Emergency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_project_value: "",
      customer_project: [],
      em_description: "",
      em_timestamp: "",
      em_ID: 0,
      able: "disabled",
      edit_mode: false,
      count: 0,
      change: false,
      titelText: "Füge neue Notfälle ein",
      previewText: "Preview",
      preview: false,
    };
  }

  componentDidMount() {
    var query = window.location.search;
    if (this.props.token) {
      this.setState({ count: 0 });
    } else {
      this.setState({ count: 1 });
    }

    fetch("/api/customer_project", {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json !== "Token missing or invalid." && json["customer"].length !== 0) {
          this.setState({ customer_project: json["customer"] });
          this.setState({
            customer_project_value: json["customer"][0]["cu_name"],
          });
        }
      });
    if (query) {
      this.setState({ edit_mode: true })
      fetch("/api/emergency" + query, {
        method: "GET",
        headers: {
          "content-Type": "application/json",
          "x-access-token": this.props.token
        }
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json !== "Token missing or invalid.") {
            this.setState({ customer_project_value: json[0]["cu_name"] })
            this.setState({ em_timestamp: json[0]["em_timestamp"] })
            this.setState({ em_description: json[0]["em_description"] })
            this.setState({ em_ID: json[0]["em_ID"] })
            this.setState({
              change: true,
              titelText: "Ändern sie einen Notfalleintrag"
            })
          }
        })
    }
  }

  markdownChangeHandler = (e) => {
    this.setState({ em_description: e });
  };

  onClickPreview = (e) => {
    if (this.state.preview) {
      this.setState({
        previewText: "Preview",
        preview: false
      })
    } else {
      this.setState({
        previewText: "Edit",
        preview: true
      })
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    var count = 0;
    var re = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/;
    let em_timestamp = this.state.em_timestamp;
    if (!re.test(em_timestamp)) {
      this.setState({ count: 6 })
      count = 6
    }
    let body = {
      em_timestamp: em_timestamp + ":00.00Z",
      em_description: this.state.em_description,
      customer_project: this.state.customer_project_value,
    };
    if (count !== 6) {
      this.setState({ count: 3 });
      fetch("/api/emergency", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json === "OK") {
            this.setState({
              em_timestamp: "",
              em_description: "",
              able: "disabled",
              count: 2,
            });
          }
          else if (json === "Every key needs a value") {
            this.setState({ count: 5 });
          }
          else if (json === "Bad Request. Wrong date format") {
            this.setState({ count: 6 });
          }
          else {
            this.setState({ count: 4 });
          }
        });
    }
  };

  onSubmitEdit = (e) => {
    e.preventDefault();
    var count = 0;
    var re = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/;
    let em_timestamp = this.state.em_timestamp;
    if (!re.test(em_timestamp)) {
      this.setState({ count: 6 })
      count = 6
    }
    let body = {
      em_ID: this.state.em_ID,
      em_timestamp: em_timestamp + ":00.00Z",
      em_description: this.state.em_description,
      customer_project: this.state.customer_project_value,
    };
    if (count !== 6) {
      this.setState({ count: 3 });
      fetch("/api/emergency", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json === "OK") {
            this.setState({
              em_timestamp: "",
              em_description: "",
              able: "disabled",
              count: 7,
              change: false,
              titelText: "Füge neue Notfälle ein"
            });
          }
          else if (json === "Every key needs a value") {
            this.setState({ count: 5 });
          }
          else if (json === "Bad Request. Wrong date format") {
            this.setState({ count: 6 });
          }
          else {
            this.setState({ count: 4 });
          }
        });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {
      customer_project,
      em_timestamp,
      em_description,
      customer_project_value,
    } = this.state;
    return (
      <div className="container-lg p-3 my-3">
        <center><h3>
          {this.state.titelText}
        </h3>
        </center>
        <form onSubmit={this.onSubmit}>
          <div className="row mt">
            <div className="col form-group mt-3 ">
              <label>Wähle ein Kundenprojekt*</label>
              <select
                className="form-control form-control"
                name="customer_project_value"
                value={customer_project_value}
                onChange={this.handleChange}
                placeholder="Kunden Wählen*"
                required
              >
                {customer_project.map((customer_project) => (
                  <option key={customer_project["cu_ID"]}>
                    {customer_project["cu_name"]}
                  </option>
                ))}
              </select>
            </div>
            <div className="col mt-3">
              <label>Datum des Notfalls*</label>
              <input
                className="form-control"
                name="em_timestamp"
                value={em_timestamp}
                placeholder="Startzeit*"
                type="datetime-local"
                onChange={this.handleChange}
                min="2021-05-11T07:00"
                required
              ></input>
            </div>
          </div>
          {!this.state.preview && (
            <div className="row mt-3">
              <div className="col">
                <ReactMde
                  name="em_description"
                  value={em_description}
                  toolbarCommands={[
                    ["header", "bold", "italic"],
                    ["unordered-list", "ordered-list"],
                  ]}
                  onChange={(e) => this.markdownChangeHandler(e)}
                  disablePreview="true"
                />
              </div>
            </div>
          )}
          {this.state.preview && (
            <div className="row p-3 m-1 mt-3">
              <div className="col">
                <ReactMarkdown>{em_description}</ReactMarkdown>
              </div>
            </div>
          )}
          {!this.state.change && (
            <div className="row mt-3 m-1 d-flex justify-content-between">
              <button
                className="btn btn-success mt-3"
                type="submit"
              >
                Absenden
              </button>
              <button onClick={this.onClickPreview} type="button" className="btn btn-info mt-3">
                {this.state.previewText}
              </button>
            </div>
          )}
          {this.state.change && (
            <div className="row m-1 mt-3 d-flex justify-content-between">
              <button
                className="btn btn-success"
                onClick={this.onSubmitEdit}
                type="button"
              >
                Ändern
              </button>

              <button onClick={this.onClickPreview} type="button" className="btn btn-info mt-3">
                {this.state.previewText}
              </button>
            </div>
          )}
        </form>
        {this.state.count === 3 && (
          <div className="spinner-border text-secondary mt-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {this.state.count === 2 && (
          <div className="alert alert-success mt-2" role="alert">
            Erfolgreich hinzugefügt
          </div>
        )}
        {this.state.count === 1 && (
          <div className="alert alert-warning mt-2" role="alert">
            Du bist nicht eingeloggt
          </div>
        )}
        {this.state.count === 4 && (
          <div className="alert alert-warning mt-2" role="alert">
            Kunde nicht Vorhanden
          </div>
        )}
        {this.state.count === 5 && (
          <div className="alert alert-warning mt-2" role="alert">
            Bitte jedes Feld ausfüllen
          </div>
        )}
        {this.state.count === 6 && (
          <div className="alert alert-warning mt-2" role="alert">
            Datum in einem falschen Format (yyyy-mm-ddThh:mm)
          </div>
        )}
        {this.state.count === 7 && (
          <div className="alert alert-success mt-2" role="alert">
            Erfolgreich geändert
          </div>
        )}
      </div>
    );
  }
}

export default Emergency;
