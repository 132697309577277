import React, { Component } from "react";
import { Redirect } from "react-router-dom";
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            proovNotAscii: false,
        };
    }
    changeHandler = (e) => {
        if (/^[\x00-\x7F]*$/.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
            this.setState({ proovNotAscii: false });
        } else {
            this.setState({ proovNotAscii: true });
        }
    };

    render() {
        if (this.props.loggedIn === false) {
            return (
                <div className="container">
                    <form onSubmit={(e) => this.props.onSubmitHaendler(e)}>
                        <center>
                            <h3 className="text-warning">Einloggen</h3>
                        </center>
                        <div className="form-group mt-2">
                            <label>Benutzername</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                value={this.state.username}
                                placeholder="Benutzername"
                                onChange={(e) => this.changeHandler(e)}
                                maxLength="50"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Passwort</label>
                            <input
                                type="password"
                                autoComplete="on"
                                name="password"
                                value={this.state.password}
                                className="form-control"
                                placeholder="Passwort"
                                onChange={(e) => this.changeHandler(e)}
                                maxLength="55"
                                required
                            />
                        </div>

                        <button type="submit" className="btn btn-danger btn-block mb-2">
                            Einloggen
                        </button>
                    </form>
                    {this.props.count === 1 && (
                        <div className="alert alert-danger">
                            Falsches Passwort oder Username oder ihr Account ist noch nicht validiert/gesperrt.
                        </div>
                    )}
                    {this.state.proovNotAscii && (
                        <div className="alert alert-warning">
                            Bitte keine Zeichen außerhalb der ASCII Tabelle
                        </div>
                    )}
                </div>
            );
        } else {
            return <Redirect to={`/home`} />;
        }
    }
}

export default Login;
