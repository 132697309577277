import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import "./pointer.css";

class Show extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delete_pressed: false,
      redirect: false,
      customer_project_value: "",
      cu_introduction: "",
      technologys: [""],
      systems: [""],
      mails: [{ ml_email: "", re_cc: false }],
      key: 0,
      deleted: "",
      fail_message: "",
      response: [],
      showCustomers: false,
      change: false,
      count: 2,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    let count = 0;
    let body = {
      cu_name: this.state.customer_project_value,
      cu_introduction: this.state.cu_introduction,
      te_name: this.state.technologys,
      sy_name: this.state.systems,
      mails: this.state.mails,
    };
    if (!(count === 1)) {
      this.setState({ count: 3 });
      fetch("/api/customer_project", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return "OK"
          } else if (response.status === 409) {
            return "Customer project already exists"
          }
          else {
            return response.json();
          }
        })
        .then((json) => {
          if (json === "OK") {
            this.setState({
              customer_project_value: "",
              cu_introduction: "",
              systems: [""],
              technologys: [""],
              mails: [{ ml_email: "", re_cc: false }],
            });
            this.componentDidMount()
            this.setState({ count: 2 })
          }
          else if (json === "Customer project already exists") {
            this.setState({ count: 4 });
          }
          else if (json === "The first letter has to be uppercase") {
            this.setState({ count: 5 });
          }
          else if (json !== "OK") {
            this.setState({ count: 6 });
          }
        });
    }
  };

  componentDidMount() {
    if (this.props.token) {
      this.setState({ count: 0 });
    } else {
      this.setState({ count: 1 });
    }
    fetch("/api/customer_project", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return "error";
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json !== "error") {
          this.setState({ response: json["customer"] });
        }
      });
  }

  onClickHaendler = (e) => {
    this.setState({ delete_pressed: true });
    this.setState({ key: e.target.id });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  systemHandler(e, index) {
    let tmp = this.state.systems;
    tmp[index] = e.target.value;
    this.setState({ systems: tmp });
  }
  technologyHandler(e, index) {
    let tmp = this.state.technologys
    tmp[index] = e.target.value;
    this.setState({ technologys: tmp });
  }

  mailsHandler(e, index) {
    let tmp = this.state.mails
    let mail_dict = tmp[index]
    if (e.target.name === "re_cc") {
      if (mail_dict["re_cc"]) {
        mail_dict["re_cc"] = false
      }
      else {
        mail_dict["re_cc"] = true
      }
    }
    else if (e.target.name === "ml_email") {
      mail_dict["ml_email"] = e.target.value
    }
    tmp[index] = mail_dict
    this.setState({ mails: tmp });
  }

  clickNoHaendler = () => {
    this.setState({ delete_pressed: false });
    this.setState({ count: 0 });
  };
  operationShow = () => {
    this.setState({ showCustomers: true });
  };

  operationHide = () => {
    this.setState({ showCustomers: false });
    this.setState({ count: 0 })
  };

  addSystem() {
    this.setState({ systems: [...this.state.systems, ""] });
  }

  addTechnology() {
    this.setState({ technologys: [...this.state.technologys, ""] });
  }

  addMail() {
    this.setState({ mails: [...this.state.mails, { ml_email: "", re_cc: false }] });
  }

  removeClickSys(i) {
    let systems = [...this.state.systems];
    systems.splice(i, 1);
    this.setState({ systems });
  }
  removeClickTech(i) {
    let technologys = [...this.state.technologys];
    technologys.splice(i, 1);
    this.setState({ technologys });
  }
  removeMail(i) {
    let mail = [...this.state.mails];
    mail.splice(i, 1);
    this.setState({ mails: mail });
  }

  clickYesHaendler = () => {
    this.setState({ delete_pressed: true });

    fetch("/api/customer_project/" + this.state.key, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          response = "OK"
          return response
        }
        else {
          return response.json();
        }
      })
      .then((json) => {
        if (json === "OK") {
          this.componentDidMount()
          this.setState({ delete_pressed: false })
        } else {
          this.setState({
            count: 7,
          });
        }
      });
  };

  onClickChange = (e) => {
    this.setState({ showCustomers: false, })
    this.setState({ key: e.target.id })
    fetch("/api/customer_project/" + e.target.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          response = "Error"
          return response
        }
        else {
          return response.json();
        }
      })
      .then((json) => {
        if (json !== "Error") {
          let technology = json["technology"].map(tech => tech["technology"])
          let systems = json["system"].map(syst => syst["system"])
          this.setState({
            customer_project_value: json["cu_name"],
            cu_introduction: json["cu_introduction"],
            technologys: technology,
            systems: systems,
            mails: json["mails"],
            change: true,
          })
        }

      });
  }

  changeEntry = () => {
    let body = {
      cu_name: this.state.customer_project_value,
      cu_introduction: this.state.cu_introduction,
      te_name: this.state.technologys,
      sy_name: this.state.systems,
      mails: this.state.mails,
      cu_ID: this.state.key
    };
    this.setState({ count: 3 });
    fetch("/api/customer_project", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return "OK"
        } else if (response.status === 409) {
          return "Customer project already exists"
        }
        else {
          return response.json();
        }
      })
      .then((json) => {
        if (json === "OK") {
          this.setState({
            customer_project_value: "",
            cu_introduction: "",
            systems: [""],
            technologys: [""],
            change: false,
            mails: [{ ml_email: "", re_cc: false }],
          });
          this.componentDidMount()
          this.setState({ count: 8 })
        }
        else if (json === "Customer project already exists") {
          this.setState({ count: 4 });
        }
        else if (json === "The first letter has to be uppercase") {
          this.setState({ count: 5 });
        }
        else if (json !== "OK") {
          this.setState({ count: 6 });
        }
      });

  }

  render() {
    const { customer_project_value, cu_introduction, systems, technologys, mails } =
      this.state;

    return (
      <div id="wrapper" className="container-lg p-3 my-3 ">
        <div className="mb-3">
          <center>
            <h3>Kunden Hinzufügen</h3>
          </center>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col form-group">
              <input
                type="text"
                className="form-control"
                name="customer_project_value"
                value={customer_project_value}
                placeholder="Kunde*"
                onChange={this.handleChange}
                maxLength="55"
                required
              ></input>
            </div>
            <div className="col form-group">
              <input
                type="text"
                className="form-control"
                name="cu_introduction"
                value={cu_introduction}
                placeholder="Name des Kundenprojekt*"
                onChange={this.handleChange}
                maxLength="55"
                required
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="col form-group">
              {this.state.systems.map((system, index) => (
                <div key={index}>
                  <input
                    className="form-control mb-2"
                    value={system}
                    placeholder="System*"
                    required
                    onChange={(e) => this.systemHandler(e, index)}
                  ></input>
                </div>
              )
              )}
            </div>
            <div>
            
              <button
                className="btn btn-secondary"
                onClick={(e) => this.addSystem(e)}
              >
                +
              </button>
            </div>
            {systems.length > 1 && (
              <div>
                <button
                  className="btn btn-secondary ml-1"
                  onClick={(e) => this.removeClickSys(e)}
                >
                  -
                </button>
              </div>
            )}
            <div className="col form-group">
              {this.state.technologys.map((technology, index) => (
                <div key={index}>
                  <input
                    className="form-control mb-2"
                    value={technology}
                    placeholder="Technologie*"
                    required
                    onChange={(e) => this.technologyHandler(e, index)}
                  ></input>
                </div>
              )
              )}
            </div>
            <div>
              <button
                className="btn btn-secondary"
                onClick={(e) => this.addTechnology(e)}
              >
                +
              </button>
            </div>
            {technologys.length > 1 && (
              <div>
                <button
                  className="btn btn-secondary ml-1"
                  onClick={(e) => this.removeClickTech(e)}
                >
                  -
                </button>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col form-group">
              {this.state.mails.map((mail, index) => (
                <div key={index} className="row">
                  <div className="col">
                    <input
                      className="form-control mb-2"
                      value={mail["ml_email"]}
                      name="ml_email"
                      placeholder="Mails*"
                      required
                      onChange={(e) => this.mailsHandler(e, index)}
                    ></input>
                  </div>
                  <div>
                    <label>
                      CC:
                    </label>
                    <input
                      className="ml-2"
                      name="re_cc"
                      type="checkbox"
                      checked={mail["re_cc"]}
                      onChange={(e) => this.mailsHandler(e, index)} />
                  </div>
                </div>
              )
              )}

            </div>
            <div>
              <button
                className="btn btn-secondary ml-2"
                onClick={(e) => this.addMail(e)}
              >
                +
              </button>
            </div>
            {mails.length > 1 && (
              <div>
                <button
                  className="btn btn-secondary ml-1"
                  onClick={(e) => this.removeMail(e)}
                >
                  -
                </button>
              </div>
            )}
          </div>
          {!this.state.change && (
            <div className="row">
              <div className="col form-group">
                <button
                  className="btn btn-success mt-1"
                  type="submit"
                >
                  Absenden
                </button>
              </div>
            </div>
          )}
          {this.state.change && (
            <div className="row">
              <div className="col form-group">
                <button
                  className="btn btn-warning mt-1"
                  type="button"
                  onClick={this.changeEntry}
                >
                  Ändern
                </button>
              </div>
            </div>
          )}
        </form>
        {
          this.state.count === 3 && (
            <div className="spinner-border text-secondary mt-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )
        }
        {
          this.state.count === 2 && (
            <div className="alert alert-success mt-2" role="alert">
              Erfolgreich hinzugefügt
            </div>
          )
        }
        {
          this.state.count === 1 && (
            <div className="alert alert-warning mt-2" role="alert">
              Du bist nicht eingeloggt
            </div>
          )
        }
        {
          this.state.count === 4 && (
            <div className="alert alert-warning mt-2" role="alert">
              Kunde bereits Vorhanden
            </div>
          )
        }
        {
          this.state.count === 5 && (
            <div className="alert alert-warning mt-2" role="alert">
              Alle angaben müssen großgeschrieben sein
            </div>
          )
        }
        {
          this.state.count === 6 && (
            <div className="alert alert-warning mt-2" role="alert">
              Es fehlen weitere Angaben
            </div>
          )
        }
        {
          this.state.count === 8 && (
            <div className="alert alert-success mt-2" role="alert">
              Erfolgreich geändert
            </div>
          )
        }
        {
          this.state.showCustomers ? (
            <table className="table table-dark table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Kundenprojekt</th>
                  <th scope="col">Löschen</th>
                  <th scope="col">Ändern</th>
                </tr>
              </thead>
              <tbody>
                {this.state.response.map((response) => (
                  <tr key={response["cu_ID"]}>
                    <th scope="row">{response["cu_ID"]}</th>
                    <td>{response["cu_name"]}</td>
                    <td>
                      {" "}
                      <button id={response["cu_ID"]} className="btn btn-dark" onClick={this.onClickHaendler} >
                        <Icon.Trash id={response["cu_ID"]} color="white" className="special" />
                      </button>
                    </td>
                    <td>
                      {" "}
                      <button id={response["cu_ID"]} className="btn btn-dark" onClick={this.onClickChange} >
                        <Icon.Gear
                          id={response["cu_ID"]}
                          color="yellow"
                          className="special"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null
        }
        <div className="text-center">
          {this.state.showCustomers === false && (
            <button
              className="btn btn-secondary mt-2"
              type="submit"
              onClick={(e) => this.operationShow(e)}
            >
              Vorhandene Kunden Anzeigen
            </button>
          )}
        </div>
        <div className="text-center">
          {this.state.showCustomers === true && (
            <button
              className="btn btn-danger mt-2"
              type="submit"
              onClick={(e) => this.operationHide(e)}
            >
              Kunden Ausblenden
            </button>
          )}
        </div>
        {
          this.state.delete_pressed && (
            <div className="row alert alert-danger mt-3 border-2-danger ">
              <div className="col d-flex bd-highlight">
                <div className=" flex-grow-1 bd-highlight">
                  <h4>
                    Willst du wirklich den Kunden mit der ID {this.state.key}
                  </h4>
                </div>
                <div className=" bd-highlight">
                  <Icon.XCircle
                    id="myIcon"
                    color="red"
                    size={40}
                    onClick={this.clickNoHaendler}
                  />
                </div>
                <div className=" bd-highlight ml-2">
                  <button
                    type="delete"
                    className="btn btn-danger border-2-danger"
                    onClick={this.clickYesHaendler}
                  >
                    Ja
                  </button>
                </div>
              </div>
            </div>
          )
        }
        {
          this.state.count === 7 && (
            <div className="alert alert-warning mt-2" role="alert">
              Dieser Kunde kann nicht gelöscht werden, da erst die zugehörigen Wartungsfenster etc. gelöscht werden müssen
            </div>
          )
        }
      </div>
    );
  }

}

export default Show;

