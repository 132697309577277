import { createGlobalStyle } from "styled-components";

export const lightTheme = {
    body: "#fff",
    fontColor: "#000",
    borderColorBtn: "#000",
    borderColorInput: "#000"
};

export const darkTheme = {
    body: "#181818",
    fontColor: "#fff",
    backroundColor: "#343a40",
    lineColor: "#d9d9d9",
    textBoxHeader: "#737373",
    borderColorBtn: "#000",
};



export const GlobalStyles = createGlobalStyle`
	body {
		background-color: ${(props) => props.theme.body};
	}
`;