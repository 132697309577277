import React, { Component } from "react";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            us_email: "",
            us_displayname: "",
            us_password: "",
            count: 0
        };
    }


    onSubmit = (e) => {
        e.preventDefault();
        let body;
        this.setState({
            count: 3
        })
        body = {
            us_email: this.state.us_email,
            us_displayname: this.state.us_displayname,
            us_password: this.state.us_password
        }
        fetch("/api/user", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.token,
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                if (json === "OK") {
                    this.setState({
                        us_email: "",
                        us_displayname: "",
                        us_password: "",
                        count: 2
                    });
                }
                else if (json === "Already exists") {
                    this.setState({
                        count: 4
                    })
                }
                else {
                    this.setState({
                        count: 5
                    })
                }
            });
    };


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });


    };
    render() {
        const {
            us_email,
            us_displayname,
            us_password,
            count
        } = this.state;

        return (
            <div className="container-lg p-3 my-3">
                <center>
                    <h3>Hier können sie sich Registrieren</h3>
                </center>
                <form onSubmit={this.onSubmit}>
                    <div className="row mt">
                        <div className="col form-group mt-3 ">
                            <input
                                className="form-control"
                                name="us_email"
                                value={us_email}
                                placeholder="Email*"
                                type="text"
                                onChange={this.onChange}
                                required
                            ></input>
                        </div>
                        <div className="col mt-3">
                            <input
                                className="form-control"
                                name="us_displayname"
                                value={us_displayname}
                                placeholder="Vor- und Nachname*"
                                type="text"
                                onChange={this.onChange}
                                required
                            ></input>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <input
                                className="form-control"
                                name="us_password"
                                value={us_password}
                                placeholder="Passwort"
                                type="password"
                                onChange={this.onChange}
                                required
                            ></input>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <button
                                className="btn btn-success mt-3"
                                type="submit"
                                onClick={(e) => this.onSubmit(e)}
                            >
                                Absenden
                            </button>
                        </div>
                    </div>
                </form>
                {count === 3 && (
                    <div className="spinner-border text-secondary mt-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
                {count === 2 && (
                    <div className="alert alert-success mt-2" role="alert">
                        Ihr Userdaten wurden übermittelt. Warten sie nun auf ihre Validierung.
                    </div>
                )}
                {count === 4 && (
                    <div className="alert alert-warning mt-2" role="alert">
                        Es besteht bereits ein Account mit dieser Email oder Displayname.
                    </div>
                )}
                {count === 5 && (
                    <div className="alert alert-warning mt-2" role="alert">
                        Bitte überprüfen sie ihr Email format.
                    </div>
                )}
            </div>
        );
    }
}

export default Signup;