import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class Report extends Component {
  state = {
    count: 0,
    re_date: "",
    customer_project_value: "",
    start_date: "",
    end_date: "",
    customer_project: [{ cu_ID: 0, cu_name: "" }],
    redirect: false
  };



  componentDidMount() {
    fetch("/api/customer_project", {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json !== "Token missing or invalid." && json["customer"].length !== 0) {
          this.setState({ customer_project: json["customer"] });
          this.setState({
            customer_project_value: json["customer"][0]["cu_name"],
          });
        };
      })
  }

  handleChange = (e) => {
    // Add a + ":00.00Z" to start and end_date
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "customer_project_value") {
      fetch("/api/customer_project", {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
      })
        .then((response) => {
          return response.json();
        })
    }
  }





  submitHandler = (e) => {
    e.preventDefault();
    var send = true;
    var re = /(\d{4})-(\d{2})/;
    if (!re.test(this.state.re_date)) {
      this.setState({ count: 9 });
      send = false;
    }
    if (send) {
      this.setState({ count: 3 });
      let body = { customer_project: this.state.customer_project_value };
      let MONTH_DICT = {
        "01": "Januar",
        "02": "Februar",
        "03": "März",
        "04": "April",
        "05": "Mai",
        "06": "Juni",
        "07": "Juli",
        "08": "August",
        "09": "September",
        "10": "Oktober",
        "11": "November",
        "12": "Dezember",
      };
      body.re_month = MONTH_DICT[this.state.re_date.substring(5, 7)];
      body.re_year = this.state.re_date.substring(0, 4);
      fetch("/api/report", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            this.setState({ count: 1 });
            return response.json();
          } else {
            return "OK";
          }
        })
        .then((json) => {
          if (json === "OK") {
            this.setState({ count: 2 });
          } else if (
            json === "Pls don't use # in the middle of the sentence"
          ) {
            this.setState({ count: 6 });
          } else {
            this.setState({ count: 7 });
          }
        });
    }
  }





  render() {
    const {
      re_date,
      customer_project,
      customer_project_value
    } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.query_string} />;
    } else {
      return (
        <div id="wrapper" className="container-lg p-3 my-3 ">
          <section>
            <div className="filme">
              <form onSubmit={this.submitHandler}>
                <center>
                  <h3>
                    Erstelle einen Report
                  </h3>
                </center>
                <div className="row">
                  <div className="col form-group m-2">
                    <div className="form-group">
                      <label>
                        Wähle ein Kundenprojekt*
                      </label>
                      <select
                        className="form-control form-control-sm"
                        name="customer_project_value"
                        value={customer_project_value}
                        onChange={this.handleChange}
                        required
                      >
                        {customer_project.map((customer_project) => (
                          <option key={customer_project["cu_ID"]}>
                            {customer_project["cu_name"]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col ml-2">
                    <input
                      className="form-control"
                      name="re_date"
                      placeholder="Jahr_Monat"
                      value={re_date}
                      onChange={this.handleChange}
                      type="month"
                    ></input>
                  </div>
                </div>
                <button type="submit" className="btn btn-success btn-block form-group mt-5" onSubmit={this.submitHandler}>
                  Absenden
                </button>
              </form>
            </div>
          </section>
          {this.state.count === 7 && (
            <div className="alert alert-warning mt-4" role="alert">
              Fehler beim erstellen von Reports aufgetreten. Bitte Ersteller
              kontaktieren
            </div>
          )}
          {this.state.count === 1 && (
            <div className="alert alert-warning mt-4" role="alert">
              Ein Fehler beim senden des Reports ist aufgetreten
            </div>
          )}
          {this.state.count === 2 && (
            <div className="alert alert-success mt-4" role="alert">
              Report wurde erfolgreich an ihre E-Mail verschickt
            </div>
          )}
          {this.state.count === 3 && (
            <div className="spinner-border text-secondary mt-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      )
    }
  }
}

export default Report;