import React, { Component } from "react";
import Bluetux from "../Bluetux.png";
import * as Icon from "react-bootstrap-icons";
import { Redirect } from "react-router-dom";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_main: false,
            redirect_emer: false,
            redirect_even: false,
            redirect_sear: false,
            redirect_cust: false,
        }
    }

    RedirectMain = (e) => {
        this.setState({
            redirect_main: true
        })
    }

    RedirectEmer = (e) => {
        this.setState({
            redirect_emer: true
        })
    }


    RedirectEven = (e) => {
        this.setState({
            redirect_even: true
        })
    }


    RedirectSear = (e) => {
        this.setState({
            redirect_sear: true
        })
    }

    RedirectCust = (e) => {
        this.setState({
            redirect_cust: true
        })
    }


    render() {
        if (this.state.redirect_main) {
            return (
                <Redirect to="/maintenance"></Redirect>
            )
        }
        else if (this.state.redirect_emer) {
            return (
                <Redirect to="/emergency"></Redirect>
            )
        }
        else if (this.state.redirect_even) {
            return (
                <Redirect to="/others"></Redirect>
            )
        }
        else if (this.state.redirect_sear) {
            return (
                <Redirect to="/search"></Redirect>
            )
        }
        else if (this.state.redirect_cust) {
            return (
                <Redirect to="/customer_project"></Redirect>
            )
        }
        else {
            return (
                <div className="container">
                    <div>
                        <h2 className="d-flex justify-content-center">
                            Die Webseite zum Erstellen unserer Service Reports
                        </h2>
                    </div>
                    <div className="d-flex justify-content-center">
                        <img
                            src={Bluetux}
                            width="300"
                            height="300"
                            className="d-inline-block center "
                            alt=""></img>
                    </div>
                    <div className="row mt-5">
                        <div className="col">
                            <button className="btn btn-light bsize" onClick={this.RedirectMain}>
                                <div>

                                    <Icon.Gear
                                        id="myIcon"
                                        color="grey"
                                        size={60}
                                        onClick={this.clickNoHaendler}
                                    />
                                </div>
                                <div>
                                    Wartungsfenster
                                </div>
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn btn-light bsize" onClick={this.RedirectEmer}>
                                <div >

                                    <Icon.ExclamationOctagon
                                        id="myIcon"
                                        color="grey"
                                        size={60}
                                        onClick={this.clickNoHaendler}
                                    />
                                </div>
                                <div >
                                    Notfall
                                </div>
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn btn-light bsize" onClick={this.RedirectEven}>
                                <div >

                                    <Icon.Calendar2Event
                                        id="myIcon"
                                        color="grey"
                                        size={60}
                                        onClick={this.clickNoHaendler}
                                    />
                                </div>
                                <div >
                                    Events
                                </div>
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn btn-light bsize" onClick={this.RedirectSear}>
                                <div>

                                    <Icon.Search
                                        id="myIcon"
                                        color="grey"
                                        size={60}
                                        onClick={this.clickNoHaendler}
                                    />
                                </div>
                                <div>
                                    Suchen
                                </div>
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn btn-light bsize" onClick={this.RedirectCust}>
                                <div>

                                    <Icon.FilePerson
                                        id="myIcon"
                                        color="grey"
                                        size={60}
                                        onClick={this.clickNoHaendler}
                                    />
                                </div>
                                <div>
                                    Kunde
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Home;
