import React, { Component } from "react";
import "./App.css";
import Home from "./components/home"
import Navbar from "./components/navbar";
import Login from "./components/login";
import Search from "./components/search"
import Maintenance from "./components/maintenance"
import Emergency from "./components/emergency"
import Others from "./components/otherEvent"
import CustomerProject from "./components/customerprojects"
import Signup from "./components/signup";
import Validate from "./components/validate";
import Report from "./components/report";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import history from './history';
import Show from "./components/show";
import styled, { ThemeProvider } from "styled-components"
import { lightTheme, darkTheme, GlobalStyles } from "./themes.js"





const StyledApp = styled.div`
  color: ${(props) => props.theme.fontColor};
  .form-control {
    background-color: ${(props) => props.theme.backroundColor};
    border: ${(props) => props.theme.backroundColor};
    color: ${(props) => props.theme.lineColor};
  }
  .mde-header {
    background: ${(props) => props.theme.textBoxHeader};
    border-color: ${(props) => props.theme.backroundColor};
  }
  .mde-text {
    background: ${(props) => props.theme.backroundColor};
    border-color: ${(props) => props.theme.backroundColor};
    color: ${(props) => props.theme.lineColor};
  }
  .custom-select {
    background: ${(props) => props.theme.backroundColor};
    border: ${(props) => props.theme.backroundColor};
    color: ${(props) => props.theme.lineColor};
  }
  .btn.btn-light.bsize {
    background: ${(props) => props.theme.backroundColor};
    border-color: ${(props) => props.theme.borderColorBtn};
    color: ${(props) => props.theme.lineColor};
  }
`;



class App extends Component {
  constructor(props) {
    super(props);


    this.state = {
      response: "",
      count: 0,
      us_admin: false,
      token: "",
      loggedIn: false,
      lightmode: false
    };
  }
  themeTogglerDark = () => {
    this.setState({ lightmode: false })
  }
  themeTogglerlight = () => {
    this.setState({ lightmode: true })
  }



  componentDidMount() {
    document.title = "Service Reports";
    let token = localStorage.getItem("token");
    let admin = localStorage.getItem("admin");
    if (token) {
      this.setState({ token: token })
      this.setState({ us_admin: admin })
      this.setState({ loggedIn: true })
      fetch("/api/customer_project", {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json === "Token missing or invalid.") {
            this.setState({ loggedIn: false })
            this.setState({ token: "" })
            localStorage.clear()
          }
        })
    }
  }


  onClickLogout = (e) => {
    this.setState({ loggedIn: false })
    localStorage.clear()
    this.setState({ token: "" })
  };


  submitHaendler = (e) => {
    e.preventDefault();
    let username = e.target.username.value;
    let password = e.target.password.value;
    var basic64 = username + ":" + password;
    fetch('/api/login', {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(basic64),
      }
    })
      .then((response) => {
        if (!response.ok) {
          return "error"
        }
        return response.json();
      })
      .then((json) => {

        if (json !== "error") {
          this.setState({ token: json["token"] });
          this.setState({ loggedIn: true });
          localStorage.setItem('token', this.state.token);
          this.setState({ us_admin: json["us_admin"] });
          localStorage.setItem('admin', this.state.us_admin);
          this.setState({ count: 0 })
        }
        else {
          this.setState({ count: 1 });
        }
      });
  };

  render() {
    const { lightmode } = this.state
    return (
      <ThemeProvider theme={lightmode === true ? lightTheme : darkTheme}>
        <GlobalStyles />
        <StyledApp>
          <React.Fragment>
            <Router history={history}>
              <Navbar


                loggedIn={this.state.loggedIn}
                us_admin={this.state.us_admin}
                lightmode={this.state.lightmode}
                onClick={this.onClickLogout}
                onClickLightOff={this.themeTogglerDark}
                onClickLightOn={this.themeTogglerlight} />
              <div>
                <Switch>
                  <Route exact path="/home">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      <Home />
                    )}
                  </Route>

                  <Route exact path="/login">
                    <div className="mt-10">
                      <Login
                        loggedIn={this.state.loggedIn}
                        count={this.state.count}
                        onSubmitHaendler={this.submitHaendler}
                      />
                    </div>
                  </Route>

                  <Route exact path="/maintenance">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      < Maintenance token={this.state.token} />
                    )}
                  </Route>

                  <Route exact path="/emergency">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      <Emergency token={this.state.token} />
                    )}
                  </Route>

                  <Route path="/show">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      <Show token={this.state.token} />
                    )}
                  </Route>

                  <Route exact path="/others">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      <Others token={this.state.token} />
                    )}
                  </Route>

                  <Route exact path="/signup">
                    <Signup token={this.state.token} />
                  </Route>

                  <Route exact path="/customer_project">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      <CustomerProject token={this.state.token} />
                    )}
                  </Route>

                  <Route exact path="/validate">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      <Validate token={this.state.token} />
                    )}
                  </Route>

                  <Route exact path="/search">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      <Search token={this.state.token} />
                    )}
                  </Route>
                  <Route exact path="/report">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      <Report token={this.state.token} />
                    )}
                  </Route>

                  <Route path="/">
                    {!this.state.loggedIn && (
                      <Redirect to="/login" />
                    )}
                    {this.state.loggedIn && (
                      <Home />
                    )}
                  </Route>


                </Switch>
              </div>
            </Router>
          </React.Fragment>
        </StyledApp>
      </ThemeProvider >
    );

  }
}

export default App;