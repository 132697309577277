import React, { Component } from "react";
import ReactMde from "react-mde";
import ReactMarkdown from 'react-markdown'
import "react-mde/lib/styles/css/react-mde-all.css";



class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_project: [{ cu_ID: 0, cu_name: "" }],
      index: "",
      technology: [{ id: 0, technology: "" }],
      ma_datetime_begin: "",
      customer_project_value: "",
      ma_datetime_end: "",
      system: [{ id: 0, system: "" }],
      ma_description: "",
      ma_maintainer: "",
      technology_value: "",
      system_value: "",
      ma_version: "",
      ml_send: false,
      ma_ID: 0,
      able: "disabled",
      count: 0,
      change: false,
      text: "Hier könnt ihr Wartungsfenster einfügen",
      previewText: "Preview",
      preview: false,
    };
  }

  componentDidMount() {
    fetch("/api/customer_project", {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json !== "Token missing or invalid." && json["customer"].length !== 0) {
          this.setState({ customer_project: json["customer"] });
          this.setState({
            customer_project_value: json["customer"][0]["cu_name"],
          });

          fetch("/api/customer_project/" + json["customer"][0]["cu_ID"], {
            method: "GET", // or 'PUT'
            headers: {
              "Content-Type": "application/json",
              "x-access-token": this.props.token,
            },
          })
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              if (
                json !== "Token missing or invalid." &&
                json !==
                "Found no system and technology to this customer project"
              ) {
                this.setState({ system: json["system"] });
                this.setState({ technology: json["technology"] });
                this.setState({ able: "" });
                this.setState({ system_value: json["system"][0]["system"] });
                this.setState({
                  technology_value: json["technology"][0]["technology"],
                });
              } else {
                this.setState({ able: "disabled" });
              }
            });
        }
      });
    if (window.location.search) {
      fetch("/api/maintenance" + window.location.search, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            return "error";
          } else {
            return response.json()
          }
        }).then((json) => {
          if (json === "error") {
            // to be continued  
          } else {
            json = json[0]
            var sy_value = json["sy_name"]
            var te_value = json["te_name"]
            this.setState({
              customer_project_value: json["cu_name"],
              ma_datetime_begin: json["ma_datetime_begin"],
              ma_datetime_end: json["ma_datetime_end"],
              ma_description: json["ma_description"],
              ma_version: json["ma_version"],
              ma_maintainer: json["ma_maintainer"],
              ma_ID: json["ma_ID"],
              ml_send: json["ml_send"],
              change: true,
              text: "Hier kann das Wartungsfenster geändert werden"
            });
          }
          var cu_ID = this.state.customer_project.filter(customer => customer["cu_name"] === json["cu_name"])[0]["cu_ID"]
          fetch("/api/customer_project/" + String(cu_ID), {
            method: "GET", // or 'PUT'
            headers: {
              "Content-Type": "application/json",
              "x-access-token": this.props.token,
            },
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                return "error"
              }
            })
            .then((json) => {
              if (json !== "error") {
                this.setState({
                  system: json["system"],
                  technology: json["technology"],
                  system_value: sy_value,
                  technology_value: te_value
                });
              }
            });

        });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "customer_project_value") {
      fetch("/api/customer_project", {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (
            json !== "Token missing or invalid." &&
            json !== "Found no system and technology to this customer project"
          ) {
            const result = json["customer"].find(
              (customer) => customer.cu_name === e.target.value
            );
            fetch("/api/customer_project/" + result.cu_ID, {
              method: "GET", // or 'PUT'
              headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.token,
              },
            })
              .then((response) => {
                return response.json();
              })
              .then((json) => {
                if (
                  json !== "Token missing or invalid." &&
                  json !==
                  "Found no system and technology to this customer project"
                ) {
                  this.setState({ system: json["system"] });
                  this.setState({ technology: json["technology"] });
                  this.setState({ able: "" });
                  this.setState({ system_value: json["system"][0]["system"] });
                }
              });
          } else {
            this.setState({ able: "disabled" });
          }
        });
    }

    //looks if the maintenance end and begins on the same day
    let date_begin = this.state.ma_datetime_begin;
    if (e.target.name === "ma_datetime_begin") {
      this.setState({ ma_datetime_end: e.target.value })
    } else if (e.target.name === "ma_datetime_end") {
      if (date_begin) {
        if (
          Number(date_begin.substring(5, 7)) !==
          Number(e.target.value.substring(5, 7)) ||
          Number(date_begin.substring(8, 10)) !==
          Number(e.target.value.substring(8, 10))
        ) {
          this.setState({ count: 6 });
        } else {
          this.setState({ count: 0 });
        }
      }
    }
  };

  markdownChangeHandler = (e) => {
    this.setState({ ma_description: e });
  };

  onClickChange = (e) => {
    e.preventDefault();
    var send = true
    let ma_datetime_begin = this.state.ma_datetime_begin;
    let ma_datetime_end = this.state.ma_datetime_end;
    var re = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/;
    if (!re.test(ma_datetime_begin) || !re.test(ma_datetime_end)) {
      this.setState({ count: 7 });
      send = false;
    }

    var regex = /^v(\d+\.)?(\d+\.)?(\*|\d+)$/;
    if (!regex.test(this.state.ma_version)) {
      if (this.state.ma_version !== "-") {
        send = false;
        this.setState({ count: 4 });
      }
    }
    let body = {
      ma_datetime_begin: ma_datetime_begin + ":00.00Z",
      ma_datetime_end: ma_datetime_end + ":00.00Z",
      ma_technic: this.state.technology_value,
      ma_system: this.state.system_value,
      ma_description: this.state.ma_description,
      ma_version: this.state.ma_version,
      ma_maintainer: this.state.ma_maintainer,
      customer_project: this.state.customer_project_value,
      ma_ID: this.state.ma_ID,
      ml_send: this.state.ml_send
    };
    if (send) {
      this.setState({ count: 3 });
      fetch("/api/maintenance", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json === "OK") {
            this.setState({
              count: 8,
            });
          } else {
            this.setState({ count: 9 });
          }
        });
    }

  }
  onClickPreview = (e) => {
    if (this.state.preview) {
      this.setState({
        previewText: "Preview",
        preview: false
      })
    } else {
      this.setState({
        previewText: "Edit",
        preview: true
      })
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    var count = 0;
    let ma_datetime_begin = this.state.ma_datetime_begin;
    let ma_datetime_end = this.state.ma_datetime_end;
    var re = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/;
    if (!re.test(ma_datetime_begin) || !re.test(ma_datetime_end)) {
      this.setState({ count: 7 });
      count = 1;
    }

    var regex = /^v(\d+\.)?(\d+\.)?(\*|\d+)$/;
    if (!regex.test(this.state.ma_version)) {
      if (this.state.ma_version !== "-") {
        count = 1;
        this.setState({ count: 4 });
      }
    }
    let body = {
      ma_datetime_begin: ma_datetime_begin + ":00.00Z",
      ma_datetime_end: ma_datetime_end + ":00.00Z",
      ma_technic: this.state.technology_value,
      ma_system: this.state.system_value,
      ma_description: this.state.ma_description,
      ma_version: this.state.ma_version,
      ma_maintainer: this.state.ma_maintainer,
      customer_project: this.state.customer_project_value,
      ml_send: this.state.ml_send
    };

    if (!(count === 1)) {
      this.setState({ count: 3 });
      fetch("/api/maintenance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json === "OK") {
            this.setState({
              ma_datetime_begin: "",
              ma_datetime_end: "",
              ma_description: "",
              ma_version: "",
              ma_maintainer: "",
              count: 2,
            });
          } else if (json === "Every key needs a value") {
            this.setState({ count: 5 });
          } else {
            this.setState({ count: 10 });
          }
        });
    }
  };

  changeCheckbox = (e) => {
    if (this.state.ml_send) {
      this.setState({ ml_send: false })
    } else {
      this.setState({ ml_send: true })
    }
  }

  render() {
    const {
      customer_project,
      technology,
      customer_project_value,
      system,
      technology_value,
      system_value,
      ma_datetime_begin,
      ma_datetime_end,
      ma_description,
      ma_maintainer,
      ma_version,
      able,
    } = this.state;

    return (
      <div className="container-lg p-3 my-3 ">
        <center>
          <h3>{this.state.text}</h3>
        </center>
        <form onSubmit={this.submitHandler}>
          <div className="row">
            <div className="col">
              <label>Durchführender Mitarbeiter*</label>
              <input
                className="form-control"
                name="ma_maintainer"
                value={ma_maintainer}
                onChange={this.handleChange}
                maxLength="25"
                required
              />
            </div>
            <div className="col form-group ">
              <label>Wähle ein Kundenprojekt*</label>
              <select
                className="form-control form-control"
                name="customer_project_value"
                value={customer_project_value}
                onChange={this.handleChange}
                placeholder="Kunden Wählen*"
                required
              >
                {customer_project.map((customer_project) => (
                  <option key={customer_project["cu_ID"]}>
                    {customer_project["cu_name"]}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <label>Version*</label>
              <input
                type="text"
                className="form-control"
                name="ma_version"
                value={ma_version}
                onChange={this.handleChange}
                maxLength="55"
                required
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group m-2">
                <label>Wähle das System*</label>
                <select
                  className="form-control form-control-sm"
                  name="system_value"
                  value={system_value}
                  onChange={this.handleChange}
                  required
                  disabled={able}
                >
                  {system.map((system_element) => (
                    <option key={system_element["id"]}>
                      {system_element["system"]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col">
              <div className="form-group m-2">
                <label>Wähle die Technologie*</label>
                <select
                  className="form-control form-control-sm"
                  name="technology_value"
                  value={technology_value}
                  onChange={this.handleChange}
                  required
                  disabled={able}
                >
                  {technology.map((technology_element) => (
                    <option key={technology_element["id"]}>
                      {technology_element["technology"]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <input
                className="form-control"
                name="ma_datetime_begin"
                placeholder="Startzeit*"
                value={ma_datetime_begin}
                onChange={this.handleChange}
                type="datetime-local"
                min="2021-05-11T07:00"
                required
              ></input>
            </div>
            <div className="col">
              <input
                className="form-control"
                name="ma_datetime_end"
                placeholder="Endzeit*"
                value={ma_datetime_end}
                onChange={this.handleChange}
                type="datetime-local"
                min="2021-05-11T07:00"
                required
              ></input>
            </div>
          </div>
          {!this.state.preview && (
            <div className="row mt-3">
              <div className="col">
                <ReactMde
                  value={ma_description}
                  name="ma_description"
                  toolbarCommands={[
                    ["header", "bold", "italic"],
                    ["unordered-list", "ordered-list"],
                  ]}
                  onChange={(e) => this.markdownChangeHandler(e)}
                  disablePreview="true"
                />
              </div>
            </div>
          )}
          {this.state.preview && (
            <div className="row p-3 border-dark m-1 mt-3">
              <div className="col">
                <ReactMarkdown>{ma_description}</ReactMarkdown>
              </div>
            </div>
          )}
          <div>
            {!this.state.change && (
              <div className="d-flex justify-content-between">
                <div>
                  <button type="submit" className="btn btn-success mt-3">
                    Absenden
                  </button>
                </div>
                <div className="mt-3">
                  <label>
                    Mail zum Kunden senden
                  </label>
                  <input
                    className="ml-2"
                    name="ml_send"
                    type="checkbox"
                    checked={this.ml_send}
                    onChange={this.changeCheckbox} />
                </div>
                <div>
                  <button type="button" onClick={this.onClickPreview} className="btn btn-info mt-3">
                    {this.state.previewText}
                  </button>
                </div>
              </div>
            )}
            {this.state.change && (
              <div className="d-flex justify-content-between">
                <div>
                  <button onClick={this.onClickChange} className="btn btn-warning mt-3">
                    Ändern
                  </button>
                </div>
                {!this.state.ml_send && (
                  <div className="mt-3">
                    <label>
                      Mail zum Kunden senden
                    </label>
                    <input
                      className="ml-2"
                      name="ml_send"
                      type="checkbox"
                      checked={this.ml_send}
                      onChange={this.changeCheckbox} />
                  </div>
                )}
                {this.state.ml_send && (
                  <div className="mt-3">
                    <label>
                      Mail wurde schon gesendet
                    </label>
                  </div>
                )}
                <div>
                  <button type="button" onClick={this.onClickPreview} className="btn btn-info mt-3">
                    {this.state.previewText}
                  </button>
                </div>
              </div>
            )}

          </div>
        </form>
        {this.state.count === 3 && (
          <div className="spinner-border text-secondary mt-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {this.state.count === 2 && (
          <div className="alert alert-success mt-2" role="alert">
            Erfolgreich hinzugefügt
          </div>
        )}
        {this.state.count === 1 && (
          <div className="alert alert-warning mt-2" role="alert">
            Du bist nicht eingeloggt
          </div>
        )}
        {this.state.count === 4 && (
          <div className="alert alert-warning mt-2" role="alert">
            Die Version hat nicht das richtige Format ("vx.x..." oder "-")
          </div>
        )}
        {this.state.count === 5 && (
          <div className="alert alert-warning mt-2" role="alert">
            Bitte in alle Felder etwas eintrage
          </div>
        )}
        {this.state.count === 6 && (
          <div className="alert alert-warning mt-2" role="alert">
            Bist du dir sicher, dass der Anfang und das Ende des
            Wartungsfensters nicht am gleich Tag sind?
          </div>
        )}
        {this.state.count === 7 && (
          <div className="alert alert-warning mt-2" role="alert">
            Datum in einem falschen Format (yyyy-mm-ddThh:mm)
          </div>
        )}
        {this.state.count === 8 && (
          <div className="alert alert-success mt-2" role="alert">
            Erfolgreich geändert
          </div>
        )}
        {this.state.count === 9 && (
          <div className="alert alert-danger mt-2" role="alert">
            Ein Fehler ist beim Ändern aufgetreten
          </div>
        )}
        {this.state.count === 10 && (
          <div className="alert alert-danger mt-2" role="alert">
            Zu lange Beschreibung
          </div>
        )}
      </div>
    );

  }
}

export default Maintenance;
