import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class Search extends Component {
  state = {
    selector: "Wartungsfenster",
    customer_project: [{ cu_ID: 0, cu_name: "" }],
    customer_project_value: "",
    technology: [],
    start_date: "",
    end_date: "",
    system: [],
    technology_value: "",
    system_value: "",
    cu_ID: "",
    able: "disabled",
    count: 0,
    query_string: "",
    redirect: false,
    re_date: "",
  };

  componentDidMount() {
    fetch("/api/customer_project", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (
          json !== "Token missing or invalid." &&
          json["customer"].length !== 0
        ) {
          this.setState({ customer_project: json["customer"] });
          this.setState({
            customer_project_value: json["customer"][0]["cu_name"],
          });
          this.setState({ cu_ID: json["customer"][0]["cu_ID"] });

          fetch("/api/customer_project/" + json["customer"][0]["cu_ID"], {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": this.props.token,
            },
          })
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              if (
                json !== "Token missing or invalid." &&
                json !==
                "Found no system and technology to this customer project"
              ) {
                this.setState({ system: json["system"] });
                this.setState({
                  system: this.state.system.concat([{ id: 0, sy_name: "" }]),
                });
                this.setState({ technology: json["technology"] });
                this.setState({
                  technology: this.state.technology.concat([
                    { id: 0, te_name: "" },
                  ]),
                });
                this.setState({ able: "" });
                this.setState({ system_value: json["system"][0]["system"] });
                this.setState({
                  technology_value: json["technology"][0]["technology"],
                });
              } else {
                this.setState({ able: "disabled" });
              }
            });
        }
      });
  }

  handleChange = (e) => {
    // Add a + ":00.00Z" to start and end_date
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "customer_project_value") {
      fetch("/api/customer_project", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.props.token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (
            json !== "Token missing or invalid." &&
            json !== "Found no system and technology to this customer project"
          ) {
            const result = json["customer"].find(
              (customer) => customer.cu_name === e.target.value
            );
            this.setState({ cu_ID: result.cu_ID });
            fetch("/api/customer_project/" + result.cu_ID, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.token,
              },
            })
              .then((response) => {
                return response.json();
              })
              .then((json) => {
                if (
                  json !== "Token missing or invalid." &&
                  json !==
                  "Found no system and technology to this customer project"
                ) {
                  this.setState({ system: json["system"] });
                  this.setState({
                    system: this.state.system.concat([""]),
                  });
                  this.setState({ technology: json["technology"] });
                  this.setState({
                    technology: this.state.technology.concat([""]),
                  });
                  this.setState({ able: "" });
                  this.setState({ system_value: json["system"][0]["system"] });
                  this.setState({
                    technology_value: json["technology"][0]["technology"],
                  });
                }
              });
          } else {
            this.setState({ able: "disabled" });
          }
        });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    var send = true;
    var re = /(\d{4})-(\d{2})/;
    re = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/;
    if (this.state.end_date && !re.test(this.state.end_date)) {
      this.setState({ count: 8 });
      send = false;
    }
    if (this.state.start_date && !re.test(this.state.start_date)) {
      this.setState({ count: 8 });
      send = false;
    }
    if (send) {
      let select_dict = {
        Wartungsfenster: "maintenance",
        Notfall: "emergency",
        "Anderes Event": "others",
        Report: "report",
      };
      let keys;
      var query_string = "/show/" + select_dict[this.state.selector] + "?";
      if (this.state.selector === "Report") {
        keys = { cu_ID: "cu_ID" }
      } else {
        keys = {
          system_value: "system",
          technology_value: "technology",
          customer_project_value: "customer_project",
          cu_ID: "cu_ID",
          end_date: "end_date",
          start_date: "start_date",
        };
      }
      for (const [key, value] of Object.entries(keys)) {
        if (this.state[key]) {
          if (key === "end_date" || key === "start_date") {
            query_string =
              query_string + value + "=" + this.state[key] + ":00.00Z&";
          }
          query_string = query_string + value + "=" + this.state[key] + "&";
        }
      }
      if (query_string[query_string.length - 1] === "&") {
        query_string = query_string.slice(0, -1);
      }
      this.setState({
        query_string: encodeURI(query_string),
        redirect: true,
      });
    }
  };

  render() {
    const {
      selector,
      technology,
      customer_project,
      customer_project_value,
      system,
      technology_value,
      system_value,
      start_date,
      end_date,
      able,
    } = this.state;

    if (this.state.redirect) {
      return <Redirect to={this.state.query_string} />;
    } else {
      return (
        <div id="wrapper" className="container-lg p-3 my-3 ">
          <section>
            <div className="filme">
              <form onSubmit={this.submitHandler}>
                <center>
                  <h3>Suche nach Komponenten</h3>
                </center>
                <div className="form-group m-2">
                  <label>Nach was wollen sie suchen?</label>
                  <select
                    className="custom-select"
                    name="selector"
                    value={this.state.selector}
                    onChange={this.handleChange}
                    required
                  >
                    <option>Wartungsfenster</option>
                    <option>Notfall</option>
                    <option>Anderes Event</option>
                    <option>Report</option>
                  </select>
                </div>
                <div className="row">
                  <div className="col form-group m-2">
                    <div className="form-group">
                      <label>Wähle ein Kundenprojekt*</label>
                      <select
                        className="form-control form-control-sm"
                        name="customer_project_value"
                        value={customer_project_value}
                        onChange={this.handleChange}
                        required
                      >
                        {customer_project.map((customer_project) => (
                          <option key={customer_project["cu_ID"]}>
                            {customer_project["cu_name"]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  {selector !== "Report" && (
                    <div className="row mt-3">
                      <div className="col ml-2">
                        <input
                          className="form-control"
                          name="start_date"
                          placeholder="Von"
                          value={start_date}
                          onChange={this.handleChange}
                          type="datetime-local"
                          min="2021-05-11T07:00"
                        ></input>
                      </div>
                      <div className="col mr-2">
                        <input
                          className="form-control"
                          name="end_date"
                          placeholder="Bis"
                          value={end_date}
                          onChange={this.handleChange}
                          type="datetime-local"
                          min="2021-05-11T07:00"
                        ></input>
                      </div>
                    </div>
                  )}
                  {selector === "Wartungsfenster" && (
                    <div className="row">
                      <div className="col form-group m-2">
                        <div className="form-group">
                          <label>Wähle das System*</label>
                          <select
                            className="form-control form-control-sm"
                            name="system_value"
                            value={system_value}
                            onChange={this.handleChange}
                            required
                            disabled={able}
                          >
                            {system.map((system) => (
                              <option key={system["id"]}>
                                {system["system"]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col form-group m-2">
                        <div className="form-group">
                          <label>Wähle die Technologie*</label>
                          <select
                            className="form-control form-control-sm"
                            name="technology_value"
                            value={technology_value}
                            onChange={this.handleChange}
                            required
                            disabled={able}
                          >
                            {technology.map((technology) => (
                              <option key={technology["id"]}>
                                {technology["technology"]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <button type="submit" className="btn btn-success btn-block">
                  Absenden
                </button>
              </form>
            </div>
          </section>
          {this.state.count === 5 && (
            <div className="alert alert-warning mt-4" role="alert">
              Keine Ergebnisse für diese Anfrage
            </div>
          )}
          {this.state.count === 6 && (
            <div className="alert alert-warning mt-4" role="alert">
              Hashes nur am Anfang benutzen. Schaue nach Fehlern
            </div>
          )}
          {this.state.count === 7 && (
            <div className="alert alert-warning mt-4" role="alert">
              Fehler beim erstellen von Reports aufgetreten. Bitte Ersteller
              kontaktieren
            </div>
          )}
          {this.state.count === 1 && (
            <div className="alert alert-warning mt-4" role="alert">
              Ein Fehler beim senden des Reports ist aufgetreten
            </div>
          )}
          {this.state.count === 2 && (
            <div className="alert alert-success mt-4" role="alert">
              Report wurde erfolgreich an ihre E-Mail verschickt
            </div>
          )}
          {this.state.count === 3 && (
            <div className="spinner-border text-secondary mt-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {this.state.count === 8 && (
            <div className="alert alert-warning mt-2" role="alert">
              Datum in einem falschen Format (yyyy-mm-ddThh:mm)
            </div>
          )}
          {this.state.count === 9 && (
            <div className="alert alert-warning mt-2" role="alert">
              Datum in einem falschen Format (yyyy-mm)
            </div>
          )}
        </div>
      );
    }
  }
}
export default Search;
