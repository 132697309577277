import React, { Component } from "react";
import { Link } from "react-router-dom";
import LogoImage from "../qbeyond.svg";
import "../App.css";
import * as Icon from "react-bootstrap-icons";

class Navbar extends Component {
  state = {
    text: "Ausloggen",
  };

  render() {
    if (!this.props.loggedIn) {
      return (
        <nav className="navbar bg-dark d-flex mb-5">
          <div>
            <Link to="/login">
              <img
                src={LogoImage}
                width="120"
                height="30"
                className="d-inline-block mt-2"
                alt=""
              />
            </Link>
            {!this.props.lightmode ? (
              <button className="btn btn-secondary ml-3"
                onClick={(e) => this.props.onClickLightOn(e)}>
                <Icon.Mask>
                  test
                </Icon.Mask>
              </button>
            ) : null}
            {this.props.lightmode ? (
              <button className="btn btn-secondary ml-3"
                onClick={(e) => this.props.onClickLightOff(e)}>
                <Icon.Mask>
                  test
                </Icon.Mask>
              </button>
            ) : null}
          </div>
          <div className="text-end">
            <Link to="/login">
              <button
                type="button"
                className="btn btn-outline-success mr-4 custom"
              >
                Einloggen
              </button>
            </Link>
            <Link to="/signup">
              <button type="button" className="btn btn-outline-warning">
                Registrieren
              </button>
            </Link>
          </div>
        </nav>
      );
    } else {
      return (
        <nav className="navbar bg-dark d-flex mb-5">
          <div>
            <Link to="/home">
              <img
                src={LogoImage}
                width="120"
                height="30"
                className="d-inline-block mt-2"
                alt=""
              />
            </Link>
            {this.props.lightmode ? (
              <button className="btn btn-secondary ml-3"
                onClick={(e) => this.props.onClickLightOff(e)}>
                <Icon.Mask>
                  test
                </Icon.Mask>
              </button>
            ) : null}
            {!this.props.lightmode ? (
              <button className="btn btn-secondary ml-3"
                onClick={(e) => this.props.onClickLightOn(e)}>
                <Icon.Mask>
                  test
                </Icon.Mask>
              </button>
            ) : null}
          </div>
          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li>
              <Link to="/home" className="nav-link px-2 text-white">
                <h5> Home</h5>
              </Link>
            </li>
            <li>
              <Link to="/maintenance" className="nav-link px-3 text-white">
                <h5>Wartungsfenster</h5>
              </Link>
            </li>
            <li>
              <Link to="/others" className="nav-link px-3 text-white">
                <h5>Andere Events</h5>
              </Link>
            </li>
            <li>
              <Link to="/emergency" className="nav-link px-3 text-white">
                <h5>Notfälle</h5>
              </Link>
            </li>
            <li>
              <Link to="/customer_project" className="nav-link px-3 text-white">
                <h5>Kunden</h5>
              </Link>
            </li>
            <li>
              <Link to="/search" className="nav-link px-3 text-white">
                <h5>Suchen</h5>
              </Link>
            </li>
            <li>
              <Link to="/report" className="nav-link px-3 text-white">
                <h5>Report</h5>
              </Link>
            </li>
          </ul>

          <div className="text-end">
            <button
              type="button"
              className="btn btn-outline-success custom mr-4"
              onClick={(e) => this.props.onClick(e)}
            >
              {this.state.text}
            </button>
            {this.props.us_admin && (
              <Link to="/validate">
                <button type="button" className="btn btn-outline-warning">
                  Validieren
                </button>
              </Link>
            )}

          </div>
        </nav>
      );
    }
  }
}

export default Navbar;
