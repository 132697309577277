import React, { Component } from "react";
import * as Icon from 'react-bootstrap-icons';
import "./pointer.css";

class Validate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delete_pressed: false,
            block_pressed: false,
            validate_pressed: false,
            admin_pressed: false,
            users: [],
            us_admin: false,
            key: "0",
            deleted: "",
            fail_message: "",
            count: 0,
            aller_us_ID: "",
        }
    };

    componentDidMount() {
        fetch("/api/user", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.token,
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                if (typeof json === 'object') {
                    this.setState({ users: json })
                }
            });
    };

    onClickHaendler = (e) => {
        this.setState({ delete_pressed: true })
        this.setState({ key: e.target.id })
    };

    onClickBlock = (e) => {
        this.setState({ block_pressed: true })
        this.setState({ key: e.target.id })
        this.setState({ us_admin: Boolean(e.target.getAttribute('us_admin')) })
    };

    onClickValidate = (e) => {
        this.setState({ validate_pressed: true })
        this.setState({ key: e.target.id })


    };

    onClickAdmin = (e) => {
        this.setState({ admin_pressed: true })
        this.setState({ key: e.target.id })
    }

    handelCheckbox = (e) => {
        let user_array;
        user_array = this.state.users
        for (var i = 0; i < user_array.length; i++) {
            if (user_array[i]["us_ID"] === Number(e.target.id)) {
                if (user_array[i]["us_admin"]) {
                    user_array[i]["us_admin"] = false
                } else {
                    user_array[i]["us_admin"] = true
                }
            }
        }
        this.setState({ users: user_array })
    }

    clickYesDelete = () => {
        this.setState({ alert_us_ID: this.state.key })
        fetch('/api/user/' + this.state.key, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.token,
            }
        })
            .then((response) => {
                if (response.status === 204) {
                    response = "User Deleted"
                    return response
                }
                else {
                    return response.json();
                }
            })
            .then((json) => {
                if (json === "User Deleted") {
                    this.componentDidMount()
                    this.setState({ count: 2 })
                    this.setState({ key: "0" })
                    this.setState({ delete_pressed: false })
                }
                else {
                    this.setState({ count: 3 })
                }
            });
    }

    clickNoHaendler = () => {
        this.setState({ delete_pressed: false })
        this.setState({ block_pressed: false })
        this.setState({ validate_pressed: false })
        this.setState({ key: "0" })
        this.setState({ count: "0" })
    }

    clickYesValidate = (e) => {
        let users = this.state.users
        let us_admin = users.filter(user => user["us_ID"] === Number(this.state.key));
        us_admin = us_admin[0]["us_admin"]
        let body = {
            us_validated: true,
            us_admin: us_admin,
            us_ID: Number(this.state.key)
        }

        this.setState({ alert_us_ID: this.state.key })
        fetch('/api/user', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.token,
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                if (json === "OK") {
                    this.componentDidMount()
                    this.setState({ count: 1 })
                    this.setState({ validate_pressed: false })
                    this.setState({ key: "0" })
                }
                else {
                    this.setState({ count: 4 });
                }
            });
    }
    clickYesBlock = (e) => {
        let body = {
            us_validated: false,
            us_admin: false,
            us_ID: Number(this.state.key)
        }
        this.setState({ alert_us_ID: this.state.key })
        fetch('/api/user', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.token,
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                if (json === "OK") {
                    this.componentDidMount()
                    this.setState({ count: 5 })
                    this.setState({ block_pressed: false })
                    this.setState({ key: "0" })
                }
                else {
                    this.setState({ count: 4 });
                }
            });
    }

    clickNoAdmin = (e) => {
        this.setState({ admin_pressed: false })
        this.setState({ key: "0" })
    }

    clickYesAdmin = (e) => {
        let body = {
            us_validated: true,
            us_admin: true,
            us_ID: Number(this.state.key)
        }
        this.setState({ alert_us_ID: this.state.key })
        fetch('/api/user', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.token,
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                if (json === "OK") {
                    this.componentDidMount()
                    this.setState({ count: 6 })
                    this.setState({ admin_pressed: false })
                    this.setState({ key: "0" })
                }
                else {
                    console.log(json)
                    this.setState({ count: 7 });
                }
            });
    }

    render() {
        return (
            <div id="wrapper" className="container-lg p-3 my-3" >
                <center>
                    <h3>
                        Valdiere die Benutzer
                    </h3>
                </center>
                {this.state.count === 2 && (
                    <div className="alert alert-success mt-2" role="alert">
                        Erfolgreich gelöscht
                    </div>
                )}
                {this.state.users.map((user) => (
                    <div key={user["us_ID"]}>
                        <ul className="list-group mt-4">
                            <li className="list-group-item mine-active">Benutzer #{String(user["us_ID"])}</li>
                            <li className="list-group-item mine">{user["us_email"]}</li>
                            <li className="list-group-item mine">{user["us_displayname"]}</li>
                            <li className="list-group-item mine">Administrator: {String(user["us_admin"])}</li>
                        </ul>
                        {
                            this.state.key !== String(user["us_ID"]) && (
                                <div className="row">
                                    <div className="col">
                                        <button type="delete" className="btn btn-danger mt-2 mr-2" id={user["us_ID"]} onClick={this.onClickHaendler}>
                                            Löschen
                                        </button>
                                    </div>
                                    {user["us_validated"] && (
                                        <div>
                                            <div>
                                                <button type="delete" className="btn btn-danger mt-2 mr-3" id={user["us_ID"]} onClick={this.onClickBlock}>Benutzer Sperren</button>
                                            </div>
                                            {!user["us_admin"] && (
                                                <div>
                                                    <button type="button" className="btn btn-primary mt-2 mr-3" id={user["us_ID"]} onClick={this.onClickAdmin}>Zum Admin machen</button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {!user["us_validated"] && (
                                        <div className="col d-flex justify-content-end">
                                            <div className="mr-3 mt-3">
                                                <label>
                                                    Administrator:
                                                </label>
                                                <input
                                                    className="ml-2"
                                                    id={user["us_ID"]}
                                                    name="us_admin"
                                                    type="checkbox"
                                                    checked={user["us_admin"]}
                                                    onChange={this.handelCheckbox} />
                                            </div>
                                            <div>
                                                <button className="btn btn-primary mt-2 mr-2" id={user["us_ID"]} us_admin={String(user["us_admin"])} onClick={(e) => this.onClickValidate(e)}>
                                                    Validieren
                                                </button>
                                            </div>
                                        </div>)}
                                </div>
                            )
                        }
                        {
                            this.state.delete_pressed && this.state.key === String(user["us_ID"]) && (
                                <div className="row alert alert-danger m-1 mt-3 border-2-danger">
                                    <div className="col d-flex bd-highlight">
                                        <div className="p-2 flex-grow-1 bd-highlight">
                                            <h6>Willst du wirklich den User mit der ID {this.state.key} löschen?</h6>
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <Icon.XCircle id="myIcon" color="red" size={40} onClick={this.clickNoHaendler} />

                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <button
                                                type="delete" className="btn btn-danger border-2-danger" onClick={this.clickYesDelete}>
                                                Ja</button>
                                        </div>
                                    </div>
                                </div>)
                        }
                        {
                            this.state.block_pressed && this.state.key === String(user["us_ID"]) && (
                                <div className="row alert alert-danger m-1 mt-3 border-2-danger">
                                    <div className="col d-flex bd-highlight">
                                        <div className="p-2 flex-grow-1 bd-highlight">
                                            <h6>Willst du wirklich den User mit der ID {this.state.key} sperren?</h6>
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <Icon.XCircle id="myIcon" color="red" size={40} onClick={this.clickNoHaendler} />

                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <button
                                                type="delete" className="btn btn-danger border-2-danger" onClick={this.clickYesBlock}>
                                                Ja</button>
                                        </div>
                                    </div>
                                </div>)
                        }
                        {
                            this.state.validate_pressed && Number(this.state.key) === user["us_ID"] && (
                                <div className="row alert alert-warning m-1 mt-3 border-2-danger">
                                    <div className="col d-flex bd-highlight">
                                        <div className="p-2 flex-grow-1 bd-highlight">
                                            <h6>Willst du wirklich den User mit der ID {this.state.key} validieren?</h6>
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <Icon.XCircle id="myIcon" color="orange" size={40} onClick={this.clickNoHaendler} />

                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <button
                                                type="delete" className="btn btn-warning border-2-warning" onClick={this.clickYesValidate}>
                                                Ja</button>
                                        </div>
                                    </div>
                                </div>)
                        }
                        {
                            this.state.admin_pressed && Number(this.state.key) === user["us_ID"] && (
                                <div className="row alert alert-warning m-1 mt-3 border-2-danger">
                                    <div className="col d-flex bd-highlight">
                                        <div className="p-2 flex-grow-1 bd-highlight">
                                            <h6>Willst du wirklich den User mit der ID {this.state.key} zum Admin machen?</h6>
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <Icon.XCircle id="myIcon" color="orange" size={40} onClick={this.clickNoAdmin} />

                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <button
                                                type="delete" className="btn btn-warning border-2-warning" onClick={this.clickYesAdmin}>
                                                Ja</button>
                                        </div>
                                    </div>
                                </div>)
                        }
                        {this.state.count === 1 && Number(this.state.alert_us_ID) === user["us_ID"] && (
                            <div className="alert alert-success mt-2" role="alert">
                                Erfolgreich validiert
                            </div>
                        )}
                        {this.state.count === 6 && Number(this.state.alert_us_ID) === user["us_ID"] && (
                            <div className="alert alert-success mt-2" role="alert">
                                Erfolgreich zum Admin gemacht
                            </div>
                        )}
                        {this.state.count === 3 && Number(this.state.alert_us_ID) === user["us_ID"] && (
                            <div className="alert alert-warning mt-2" role="alert">
                                Lösche erst alle Wartungsfenster, Notfälle, Reports und ander Events
                            </div>
                        )}
                        {this.state.count === 4 && Number(this.state.alert_us_ID) === user["us_ID"] && (
                            <div className="alert alert-warning mt-2" role="alert">
                                Du hast nicht die Rechte zum validieren oder es ist ein Fehler aufgetreten
                            </div>
                        )}
                        {this.state.count === 5 && Number(this.state.alert_us_ID) === user["us_ID"] && (
                            <div className="alert alert-success mt-2" role="alert">
                                Erfolgreich Gesperrt
                            </div>
                        )}
                        {this.state.count === 7 && Number(this.state.alert_us_ID) === user["us_ID"] && (
                            <div className="alert alert-success mt-2" role="alert">
                                Fehler beim zum Admin machen aufgetreten
                            </div>
                        )}
                    </div>
                ))
                }

            </div>
        );

    }
}

export default Validate;