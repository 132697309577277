import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Redirect } from "react-router-dom";
import "./pointer.css";

class Show extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delete_pressed: false,
      key: 0,
      allert_ID: 0,
      deleted: "",
      fail_message: "",
      customer_project: "",
      count: 3,
      redirect: false,
      edit: false,
      response: "",
      query_string: "",
      selector: "",
      redirect_maintenance: false,
    };
  }

  componentDidMount() {
    // Work the query shit
    let path = window.location.pathname;
    let selector = path.split("/");

    selector = selector[selector.length - 1];
    this.setState({ selector: selector });
    fetch("/api/" + selector + window.location.search, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return "error";
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json !== "error" && json.length !== 0) {
          this.setState({ response: json });
          this.setState({ customer_project: json[0]["cu_name"] });
          this.setState({ count: 0 });
        } else {
          this.setState({ count: 5 });
        }
      });
  }

  onClickHaendler = (e) => {
    this.setState({ delete_pressed: true });
    this.setState({ key: Number(e.target.id) });
  };

  clickNoHaendler = () => {
    this.setState({ delete_pressed: false });
    this.setState({ key: 0 });
    this.setState({ allert_ID: 0 });
    this.setState({ count: 0 });
  };

  onClickEditMaintenance = (e) => {
    var query_string = "/maintenance?ma_ID=" + e.target.id;
    this.setState({
      query_string: encodeURI(query_string),
      edit: true,
    });
  };
  onClickPdfLink = (e) => {
    fetch("/api/report/" + e.target.id, {
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        //Create a Blob from the PDF Stream
        //Build a URL from the file
        const fileURL = URL.createObjectURL(blob);
        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
      });
  };

  onClickBack = () => {
    this.setState({ redirect: true });
  };

  onClickEditEmergency = (e) => {
    var query_string = "/emergency?em_ID=" + e.target.id;
    this.setState({
      query_string: encodeURI(query_string),
      edit: true,
    });
  };

  onClickEditOther = (e) => {
    var query_string = "/others?ot_ID=" + e.target.id;
    this.setState({
      query_string: encodeURI(query_string),
      edit: true,
    });
  };

  clickYesHaendler = (e) => {
    this.setState({ delete_pressed: true });
    let selector = this.state.selector;
    let id_name;
    if (selector === "maintenance") {
      id_name = "ma_ID";
    } else if (selector === "emergency") {
      id_name = "em_ID";
    } else if (selector === "others") {
      id_name = "ot_ID";
    } else if (selector === "report") {
      id_name = "re_ID";
    }

    fetch("/api/" + selector + "/" + this.state.key, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          response = "OK";
          return response;
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json === "OK") {
          var number;
          var response = this.state.response;
          this.setState({ delete_pressed: false });
          this.setState({ deleted: true });
          for (var index in response) {
            if (response[index][id_name] === Number(this.state.key)) {
              number = index;
            }
          }
          response.splice(number, 1);
          this.setState({ response: response });
          this.setState({ count: 1 });
          this.setState({ allert_ID: this.state.key });
          this.setState({ key: 0 });
        } else {
          this.setState({ count: 2 });
          this.setState({ allert_ID: this.state.key });
        }
      });
  };

  clickYesHaendlerReport = (e) => {
    this.setState({ delete_pressed: true });
    let selector = this.state.selector;
    let id_name;
    if (selector === "report") {
      id_name = "re_ID";
    }

    fetch("/api/report/" + this.state.key, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          response = "OK";
          return response;
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json === "OK") {
          var number;
          var response = this.state.response;
          this.setState({ delete_pressed: false });
          this.setState({ deleted: true });
          for (var index in response) {
            if (response[index][id_name] === Number(this.state.key)) {
              number = index;
            }
          }
          response.splice(number, 1);
          this.setState({ response: response });
          this.setState({ count: 1 });
          this.setState({ allert_ID: this.state.key });
          this.setState({ key: 0 });
        } else {
          this.setState({ count: 2 });
          this.setState({ allert_ID: this.state.key });
        }
      });
  };

  clickZurueck = () => {
    this.setState({ redirect: true });
  };

  render() {
    if (this.state.count === 3) {
      return (
        <div id="wrapper" className="container-lg p-3 my-3">
          <div className="spinner-border text-secondary mt-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    if (this.state.redirect) {
      return <Redirect to="/search" />;
    }
    if (this.state.edit) {
      return <Redirect to={this.state.query_string} />;
    }
    if (this.state.count === 5) {
      return (
        <div id="wrapper" className="container-lg p-3 my-3">
          <center>
            <h3>Es ist ein Fehler aufgetreten:</h3>
          </center>
          <div className="alert alert-danger mt-3" role="alert">
            Leider konnte zu dieser Suchanfrage kein Ergebnis gefunden werden.
          </div>
          <button className="btn btn-dark mt-2" onClick={this.clickZurueck}>
            Zurück
          </button>
        </div>
      );
    } else if (this.state.selector === "emergency") {
      return (
        <div id="wrapper" className="container-lg p-3 my-3">
          <center>
            <h3>Hier finden sie die gefilterten Notfälle</h3>
          </center>
          {this.state.count === 1 && (
            <div className="alert alert-success mt-2" role="alert">
              Notfall {this.state.allert_ID} erfolgreich gelöscht
            </div>
          )}
          {this.state.response.map((response) => (
            <div key={response["em_ID"]}>
              <ul className="list-group mt-5">
                <li className="list-group-item mine" aria-current="true">
                  {this.props.selector} #{response["em_ID"]}
                </li>
                <li className="list-group-item mine">
                  {response["em_timestamp"]}
                </li>
                <li className="list-group-item mine">{response["cu_name"]}</li>
                <li className="list-group-item mine">
                  {response["em_description"]}
                </li>
              </ul>
              <div className="row d-flex justify-content-between m-1">
                {this.state.key !== response["em_ID"] && (
                  <button
                    type="delete"
                    id={response["em_ID"]}
                    className="btn btn-danger mt-2"
                    onClick={this.onClickHaendler}
                  >
                    Löschen
                  </button>
                )}
                <button
                  id={response["em_ID"]}
                  className="btn btn-secondary float-right mt-2"
                  onClick={this.onClickEditEmergency}
                >
                  Bearbeiten
                </button>
              </div>
              {this.state.delete_pressed &&
                this.state.key === response["em_ID"] && (
                  <div className="row alert alert-danger m-1 mt-3 border-2-danger">
                    <div className="col d-flex bd-highlight">
                      <div className="p-2 flex-grow-1 bd-highlight">
                        <h6>
                          Willst du wirklich den Notfall Eintrag mit der ID{" "}
                          {this.state.key} löschen?
                        </h6>
                      </div>
                      <div className="p-2 bd-highlight">
                        <Icon.XCircle
                          id="myIcon"
                          color="red"
                          size={40}
                          onClick={this.clickNoHaendler}
                        />
                      </div>
                      <div className="p-2 bd-highlight">
                        <button
                          type="delete"
                          className="btn btn-danger border-2-danger"
                          onClick={this.clickYesHaendler}
                        >
                          Ja
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              {this.state.count === 2 &&
                this.state.allert_ID === response["em_ID"] && (
                  <div className="alert alert-danger mt-2" role="alert">
                    Fehler beim Löschen aufgetreten
                  </div>
                )}
            </div>
          ))}

          <button className="btn btn-dark mt-2" onClick={this.clickZurueck}>
            Zurück
          </button>
        </div>
      );
    } else if (this.state.selector === "others") {
      return (
        <div id="wrapper" className="container-lg p-3 my-3">
          <center>
            <h3>Hier finden sie die gefilterten anderen Events</h3>
          </center>
          {this.state.count === 1 && (
            <div className="alert alert-success mt-2" role="alert">
              Anderes Event {this.state.allert_ID} erfolgreich gelöscht
            </div>
          )}
          {this.state.response.map((response) => (
            <div key={response["ot_ID"]}>
              <ul className="list-group mt-5">
                <li className="list-group-item mine" aria-current="true">
                  {this.props.selector} #{response["ot_ID"]}
                </li>
                <li className="list-group-item mine">
                  {response["ot_timestamp"]}
                </li>
                <li className="list-group-item mine">{response["cu_name"]}</li>
                <li className="list-group-item mine">
                  {response["ot_description"]}
                </li>
              </ul>
              <div className="row d-flex justify-content-between m-1">
                {Number(this.state.key) !== response["ot_ID"] && (
                  <button
                    type="delete"
                    id={response["ot_ID"]}
                    className="btn btn-danger mt-2"
                    onClick={this.onClickHaendler}
                  >
                    Löschen
                  </button>
                )}
                <button
                  id={response["ot_ID"]}
                  className="btn btn-secondary float-right mt-2"
                  onClick={this.onClickEditOther}
                >
                  Bearbeiten
                </button>
              </div>
              {this.state.delete_pressed &&
                this.state.key === response["ot_ID"] && (
                  <div className="row alert alert-danger m-1 mt-3 border-2-danger">
                    <div className="p-2 flex-grow-1 bd-highlight">
                      <h6>
                        Willst du wirklich das Event mit der ID {this.state.key}{" "}
                        löschen?
                      </h6>
                    </div>
                    <div className="p-2 bd-highlight">
                      <Icon.XCircle
                        id="myIcon"
                        color="red"
                        size={40}
                        onClick={this.clickNoHaendler}
                      />
                    </div>
                    <div className="p-2 bd-highlight">
                      <button
                        type="delete"
                        className="btn btn-danger border-2-danger"
                        onClick={this.clickYesHaendler}
                      >
                        Ja
                      </button>
                    </div>
                  </div>
                )}
              {this.state.count === 2 &&
                this.state.allert_ID === response["ot_ID"] && (
                  <div className="alert alert-danger mt-2" role="alert">
                    Fehler beim Löschen aufgetreten
                  </div>
                )}
            </div>
          ))}
          <button className="btn btn-dark mt-2" onClick={this.clickZurueck}>
            Zurück
          </button>
        </div>
      );
    } else if (this.state.selector === "maintenance") {
      return (
        <div id="wrapper" className="container-lg p-3 my-3 ">
          <center>
            <h3>Hier finden sie die gefilterten Wartungsfenster</h3>
          </center>
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Technologie</th>
                <th scope="col">Sytstem</th>
                <th scope="col">Startzeit</th>
                <th scope="col">Endzeit</th>
                <th scope="col">Version</th>
                <th scope="col">Beschreibung</th>
                <th scope="col">Kundenprojekt</th>
                <th scope="col">Durchführer</th>
                <th scope="col">Löschen</th>
                <th scope="col">Ändern</th>
              </tr>
            </thead>
            <tbody>
              {this.state.response.map((response) => (
                <tr key={response["ma_ID"]}>
                  <th scope="row">{response["ma_ID"]}</th>
                  <td>{response["te_name"]}</td>
                  <td>{response["sy_name"]}</td>
                  <td>{response["ma_datetime_begin"]}</td>
                  <td>{response["ma_datetime_end"]}</td>
                  <td>{response["ma_version"]}</td>
                  <td>{response["ma_description"]}</td>
                  <td>{response["cu_name"]}</td>
                  <td>{response["ma_maintainer"]}</td>
                  <td>
                    <button
                      id={response["ma_ID"]}
                      className="btn btn-dark"
                      onClick={this.onClickHaendler}
                    >
                      <Icon.Trash
                        id={response["ma_ID"]}
                        color="white"
                        className="special"
                      />
                    </button>
                  </td>
                  <td>
                    <button
                      id={response["ma_ID"]}
                      className="btn btn-dark"
                      onClick={this.onClickEditMaintenance}
                    >
                      <Icon.Gear
                        id={response["ma_ID"]}
                        color="yellow"
                        className="special"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {this.state.delete_pressed && (
            <div className="row alert alert-danger m-1 border-2-danger ">
              <div className="col d-flex bd-highlight">
                <div className=" flex-grow-1 bd-highlight">
                  <h4>
                    Willst du wirklich das Wartungsfenster mit der ID{" "}
                    {this.state.key} löschen?
                  </h4>
                </div>
                <div className=" bd-highlight">
                  <Icon.XCircle
                    id="myIcon"
                    color="red"
                    size={40}
                    onClick={this.clickNoHaendler}
                  />
                </div>
                <div className=" bd-highlight ml-2">
                  <button
                    type="delete"
                    className="btn btn-danger border-2-danger"
                    onClick={this.clickYesHaendler}
                  >
                    Ja
                  </button>
                </div>
              </div>
            </div>
          )}
          <button className="btn btn-dark mt-2" onClick={this.clickZurueck}>
            Zurück
          </button>
          {this.state.count === 1 && (
            <div className="alert alert-success mt-2" role="alert">
              Erfolgreich gelöscht
            </div>
          )}
          {this.state.count === 2 && (
            <div className="alert alert-danger mt-2" role="alert">
              Fehler beim Löschen aufgetreten
            </div>
          )}
        </div>
      );
    } else if (this.state.selector === "report") {
      return (
        <div id="wrapper" className="container-lg p-3 my-3 ">
          <center>
            <h3>
              Hier finden sie alle Service-Reports zu{" "}
              {this.state.customer_project}{" "}
            </h3>
          </center>
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Monat</th>
                <th scope="col">Jahr</th>
                <th scope="col">Öffnen</th>
                <th scope="col">Löschen</th>
              </tr>
            </thead>
            <tbody>
              {this.state.response.map((response) => (
                <tr key={response["re_ID"]}>
                  <th scope="row">{response["re_ID"]}</th>
                  <td>{response["re_month"]}</td>
                  <td>{response["re_year"]}</td>
                  <td>
                    <button
                      id={response["re_ID"]}
                      className="btn btn-secondary"
                      onClick={this.onClickPdfLink}
                    >
                      Öffnen
                    </button>
                  </td>
                  <td>
                    <button
                      id={response["re_ID"]}
                      className="btn btn-dark"
                      onClick={this.onClickHaendler}
                    >
                      <Icon.Trash
                        id={response["re_ID"]}
                        color="white"
                        className="special"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {this.state.delete_pressed && (
            <div className="row alert alert-danger m-1 border-2-danger ">
              <div className="col d-flex bd-highlight">
                <div className=" flex-grow-1 bd-highlight">
                  <h4>
                    Willst du wirklich den Report mit der ID {this.state.key}{" "}
                    löschen?
                  </h4>
                </div>
                <div className=" bd-highlight">
                  <Icon.XCircle
                    id="myIcon"
                    color="red"
                    size={40}
                    onClick={this.clickNoHaendler}
                  />
                </div>
                <div className=" bd-highlight ml-2">
                  <button
                    type="delete"
                    className="btn btn-danger border-2-danger"
                    onClick={this.clickYesHaendlerReport}
                  >
                    Ja
                  </button>
                </div>
              </div>
            </div>
          )}
          <button className="btn btn-dark mt-2" onClick={this.clickZurueck}>
            Zurück
          </button>
          {this.state.count === 1 && (
            <div className="alert alert-success mt-2" role="alert">
              Erfolgreich gelöscht
            </div>
          )}
          {this.state.count === 2 && (
            <div className="alert alert-danger mt-2" role="alert">
              Fehler beim Löschen aufgetreten
            </div>
          )}
        </div>
      );
    }
  }
}

export default Show;
